<template>
  <ul class="hashtags rst--list pad--top-sm pad--bottom-sm">
    <li :key="index" v-for="(tag, index) in hashtagsFiltered" :class="{isActive: selectedIndex === index}" class="hashtags-tag pad--left-sm pad--right-sm" :style="{color: tag.color}">
      <button class="hashtags-tag-button rst--button" @click.stop.prevent="selectItem(index)">{{tag.name}}</button>
    </li>
  </ul>
</template>

<script>
export default {
  props: {
    hashtags: Array,
    query: String,
    selectedHashtags: Array
  },
  data: () => ({
    selectedIndex: 0
  }),
  computed: {
    hashtagsFiltered () {
      const res =  this.query?.length ? this.hashtags.filter(item => {
        return (item.name.toLowerCase().startsWith(`#${this.query.toLowerCase()}`) && !this.selectedHashtags.includes(`#${item.name}`));
      }).slice(0, 5) : this.hashtags.filter((tag) =>  !this.selectedHashtags.includes(`${tag.name}`));
      
      return res.length ? res : [{name: this.query}]
    },
  },
  watch: {
    query () {
      this.selectedIndex = 0;
      this.$emit('hover', this.hashtagsFiltered[0])
    },
    selectedIndex (index) {
      this.$emit('hover', this.hashtagsFiltered[index])
    }
  },
  mounted () {
    this.$emit('hover', this.hashtagsFiltered[this.selectedIndex])
  },
  methods: {
    onKeyDown(event) {
      if (event.key === 'ArrowUp') {
        event.preventDefault();
        this.upHandler()
        return true
      }

      if (event.key === 'ArrowDown') {
        event.preventDefault();
        this.downHandler()
        return true
      }

      if (event.key === 'Enter') {
        event.preventDefault();
        this.enterHandler()
        return true
      }

      return false
    },

    upHandler() {
      this.selectedIndex = ((this.selectedIndex + this.hashtagsFiltered.length) - 1) % this.hashtagsFiltered.length
    },

    downHandler() {
      this.selectedIndex = (this.selectedIndex + 1) % this.hashtagsFiltered.length
    },

    enterHandler() {
      this.selectItem(this.selectedIndex)
    },

    selectItem(index) {
      const hashtag = this.hashtagsFiltered[index]

      if (hashtag) {
        this.$emit('select', hashtag)
      }
    }
  }
}
</script>

<style lang="scss" scoped>
.hashtags {
  border: solid thin white;
  border-radius: .25rem;
  background: #151C23;

  &-tag {
    border-bottom: solid thin transparent;
    border-top: solid thin transparent;

    &-button {
      height: 3rem;
      min-width: 100%;
      display: inline-block;
    }
  }
}
.isActive {
  background-color: scale-color(#151C23, $lightness: 10%);
}

</style>