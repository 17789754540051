<script>
import {h} from 'vue';
import Hashtags from './Hashtags.vue';
import {re_weburl} from '@/lib/regex';
import sanitizeHtml from 'sanitize-html';

export default {
  props: {
    modelValue: Array,
    hashtags: Array,
    defaultContent: Array,
    selectedHashtags: Array
  },
  events: ['update:modelValue'],
  data: () => ({
    anchorNodeIndex: 0,
    anchorOffset: 0,
    showHashtags: false,
    query: null,
    caretPosition: null,
    content: null,
    currentTag: null,
    hasFocus: false
  }),
  computed: {
    hashtagsStyle () {
      return {
        left: this.caretPosition?.left - (this.browserWidth > 600 ? 176 : 0) + 'px'
      }
    },
    browserWidth () {
      return this.$store.state.browserWidth
    }
  },
  watch: {
    modelValue () {
      this.$nextTick(() => {
        const test = this.$refs?.test.innerHTML;
        const input = this.$refs?.input.innerHTML;

        if (input !== test) {
          const offsetDiff = test.length - input.length;
          this.observer.disconnect();
          this.$refs.input.innerHTML = test;
          this.anchorOffset = this.anchorOffset + offsetDiff;
          this.observer.observe(this.$refs?.input, {
            subtree: true,
            characterData: true,
            childList: true
          });
        }

        let node = this.$refs.input.childNodes[this.anchorNodeIndex];
        
        if (this.hasFocus) {
          if (node) {
            setCurrentCursorPosition(node, (this.anchorOffset || 1))
          } else if (this.$refs.input.firstChild) {
            setCurrentCursorPosition(this.$refs.input.firstChild, this.$refs.input.firstChild.nodeValue.length)
          } else {
            setCurrentCursorPosition(this.$refs.input, 0)
          }
        }
        
      });
    }
  },
  mounted () {
    this.observer = this.observer || new MutationObserver(this.handleMutation);

    this.$nextTick(() => {
      const test = this.$refs?.test.innerHTML;
      const input = this.$refs?.input.innerHTML;
      
      if (input !== test) {
        this.observer.disconnect();
        this.$refs.input.innerHTML = test;
        this.$nextTick(() => {
            this.observer.observe(this.$refs?.input, {
            subtree: true,
            characterData: true,
            childList: true
          });
        })
      }
      this.observer.observe(this.$refs?.input, {
        subtree: true,
        characterData: true,
        childList: true
      });
    })
  },
  methods: {
    handlePaste (e) {
      e.preventDefault();
      let paste = (e.clipboardData || window.clipboardData).getData('text');
      paste = sanitizeHtml(paste.trim());

      const selection = window.getSelection();
      const {anchorNode, anchorOffset} = selection;
      if (!selection.rangeCount) return false;
      console.log(anchorNode, anchorOffset)
      selection.deleteFromDocument();
      const first = anchorNode.nodeValue.slice(0, anchorOffset);
      const last = anchorNode.nodeValue.slice(anchorOffset);
      anchorNode.nodeValue = first + paste + last;
      setCurrentCursorPosition(anchorNode, (first.length + paste.length));
      this.pastEvent = true;
    },
    handleInputClick () {
      const selection = window.getSelection();
      const {anchorNode, anchorOffset} = selection;

      if (anchorNode.nodeValue) {
        const [start, end] = getWordBoundsAtPosition(anchorNode.nodeValue, anchorOffset);
        const currentWord = anchorNode.nodeValue.substring(start, end);
        if (currentWord.charAt(0) === '#') {
          const range = selection.getRangeAt(0)
          this.caretPosition = range.getClientRects()[0];
          this.query = null
          this.showHashtags = true;
        } else {
          this.showHashtags = false;
        }
      }
    },
    handleMutation (mutations) {
      mutations.forEach(() => {
        const selection = window.getSelection();
        const {anchorNode, anchorOffset} = selection;

        if (anchorNode.nodeValue) {
          const [start, end] = getWordBoundsAtPosition(anchorNode.nodeValue, anchorOffset);
          const currentWord = anchorNode.nodeValue.substring(start, end);
          this.showHashtags = currentWord.charAt(0) === '#';

          if (this.showHashtags) {
            this.query = currentWord.substring(1);
            const range = selection.getRangeAt(0)
            this.caretPosition = range.getClientRects()[0];
          } else {
            this.query = null
          }
        } else {
          this.showHashtags = false
        }  

        const children = Array.from(this.$refs.input.childNodes);
        if (this.$refs.input === anchorNode) {
          this.anchorNodeIndex = anchorOffset;
          this.anchorOffset = 0;
        } else {
          this.anchorNodeIndex = children.indexOf(anchorNode);
          this.anchorOffset = anchorOffset;
        }

        const parseDom = (accumulator, node) => {
          let {nodeValue, nodeType} = node
          if (nodeType === 3) {
            accumulator.push(nodeValue);
          }

          if (nodeType === 1) {
            let children = node.childNodes.length ? Array.from(node.childNodes).reduce(parseDom, []) : null
            accumulator.push({tagName: node.tagName, children})
          }
          return accumulator
        }

        this.$emit('update:modelValue', children.reduce(parseDom, []))
      });
    },
    handleKeydown (e) {
      if (this.showHashtags) {
        this.$refs.hashtags?.onKeyDown(e)
      }

      switch (e.key) {
        case "Enter":
          if (!e.shiftKey) {
            e.preventDefault();
            this.$emit('submit')
          }
          break;
      }
    },
    handleKeyup () {
      
    },
    handleHashtagSelect (tag) {
      const {anchorNode, anchorOffset} = window.getSelection();

      if (anchorNode.nodeValue) {
        const [start, end] = getWordBoundsAtPosition(anchorNode.nodeValue, anchorOffset);
        const first = anchorNode.nodeValue.slice(0, start);
        const last = anchorNode.nodeValue.slice(end);
        anchorNode.nodeValue = first + tag.name + last;
        setCurrentCursorPosition(anchorNode, (first.length + tag.name.length + 1));
      }
      
      this.$nextTick(()=> {
        this.showHashtags = false
        this.query = null
      }) 
    },
    handleHashtagHover (tag) {
      this.currentTag = tag;
    }
  },
 render () {
   return h('div',
      {class: 'editor origin'}, [
        h('p', {
          ref: 'test',
          style: {display: 'none'}
        },
        this.modelValue.reduce((accumulator, node) => {
          switch(typeof node) {
            case "string":
              accumulator.push(node);
              break;
            
            case 'object':
              accumulator.push(h(node.tagName, {}, node.children));
              break;
          }
          return accumulator
        }, [])),
        h(
          'p',
          {
            ref: "input",
            class: 'editor-input' + (this.showHashtags ? ' is-tagging' : ''),
            contenteditable: true,
            onPaste: this.handlePaste,
            onKeyup: this.handleKeyup,
            onKeydown: this.handleKeydown,
            onClick: this.handleInputClick,
            onFocusin: () => {
              this.hasFocus = true;
              
              if (this.autoCloseHashtag) {
                clearTimeout(this.autoCloseHashtag)
              }
            },
            onFocusout: () => {
              this.hasFocus = false;

              if (this.autoCloseHashtag) {
                clearTimeout(this.autoCloseHashtag)
              }

              this.autoCloseHashtag = setTimeout(() => {
                this.showHashtags = false
              }, 250)
            }
          },
        ),
        h('p', {
          ref: 'editor',
          class: 'editor-display pos--top-left pos--bottom-right',
        }, this.modelValue.reduce((accumulator, node) => {
          switch(typeof node) {
            case "string":
              accumulator = node.split(/(#[a-z0-9_]+)/g).reduce((accumulator, node) => {
                if (node.charAt(0) === '#') {
                  const tag = this.hashtags.find(tag => node === tag.name);
                  accumulator.push(h('span', {class: 'hashtag', style: {color: tag ? tag.color : this.currentTag?.color}}, node));
                } else if (re_weburl.test(node)) { 
                  const matches = node.match(re_weburl);
                  
                  for (const match of matches) {
                    const nodeSplit = node.split(match);
                    const start = nodeSplit.shift();
                    accumulator.push(start);
                    accumulator.push(h('span', {class: 'link'}, match));
                    console.log(match)
                    node = nodeSplit.join(match)
                  }

                  accumulator.push(node)
                } else {
                  accumulator.push(node)
                }
                return accumulator
              }, accumulator)
              break;
            
            case 'object':
              accumulator.push(h(node.tagName, {}, node.children));
              break;
          }
          return accumulator
        }, [])),
        this.showHashtags ? h(Hashtags, {
          ref: 'hashtags',
          hashtags: this.hashtags,
          query: this.query,
          selectedHashtags: this.selectedHashtags,
          onSelect: this.handleHashtagSelect,
          onHover: this.handleHashtagHover,
          class: 'editor-suggestions pos--top-left',
          style: this.hashtagsStyle
        }) : null
      ]
    )
   }
 }

 function getWordBoundsAtPosition(str, position) {
  const isSpace = (c) => /\s/.exec(c);
  let start = position - 1;
  let end = position;

  while (start >= 0 && !isSpace(str[start])) {
    start -= 1;
  }
  start = Math.max(0, start + 1);

  while (end < str.length && !isSpace(str[end])) {
    end += 1;
  }
  end = Math.max(start, end);
  
  return [start, end];
}


function createRange(node, chars, range) {
    if (!range) {
        range = document.createRange()
        range.selectNode(node);
        range.setStart(node, 0);
    }

    if (chars.count === 0) {
        range.setEnd(node, chars.count);
    } else if (node && chars.count >0) {
        if (node.nodeType === Node.TEXT_NODE) {
            if (node.textContent.length < chars.count) {
                chars.count -= node.textContent.length;
            } else {
                range.setEnd(node, chars.count);
                chars.count = 0;
            }
        } else {
           for (var lp = 0; lp < node.childNodes.length; lp++) {
                range = createRange(node.childNodes[lp], chars, range);

                if (chars.count === 0) {
                    break;
                }
            }
        }
    } 

    return range;
}

function setCurrentCursorPosition(node, chars) {
    if (chars >= 0) {
        var selection = window.getSelection();

        const range = createRange(node, { count: chars });

        if (range) {
            range.collapse(false);
            selection.removeAllRanges();
            selection.addRange(range);
        }
    }
}
</script>

<style lang="scss">

.editor {
  border: solid thin white;
  border-radius: .25rem;
  background-color: #151C23;

  &-input {
    color: transparent;
    caret-color: white;
    padding: .5rem;
    padding-right: 4rem;

    &.is-tagging {
      caret-color: $red-violet;
    }
  }

  &-display {
    pointer-events: none;
    padding: .5rem;
    padding-right: 4rem;
  }

  &-suggestions {
    transform: translate(-1rem, -100%)
  }
}

.hashtag {
  color: $red-violet;
}

.link {
  color: lightseagreen;
}
</style>


