<template>
  <div class="app fll--parent origin ovr--hidden" :class="browserWidth > 600 ? 'isWideScreen' : 'isNarrowScreen'">
    <ul
      ref="messages"
      class="
        app-messages
        rst--list
        pos--bottom-left pos--bottom-right
        pad--left-md
        ovr--scroll-y
      "
      :class="{showSidebar}"
    >
      <li
        class="app-messages-message"
        :key="key"
        v-for="(message, key) in messages"
      >
        <template v-if="message.type !== 'todo'">
          <ul class="rst--list flx--wrap gtr--xs">
            <li :key="index" v-for="(hashtagId, index) in message.hashtags" :style="{color: $store.state.hashtags.all[hashtagId].color}">
              <small class="typ--i">{{$store.state.hashtags.all[hashtagId].name}}</small>
            </li>
            <li>
              <small class="message-date typ--iii-1">{{ distanceInWords(message.date) }} ago</small>
            </li>
          </ul>
          <Message :value="message.content" />
        </template>
        <template v-if="message.type === 'todo'">
          <ul class="rst--list flx--wrap gtr--xs">
            <li :key="index" v-for="(hashtagId, index) in message.hashtags" :style="{color: $store.state.hashtags.all[hashtagId].color}">
              <small class="typ--i">{{$store.state.hashtags.all[hashtagId].name}}</small>
            </li>
            <li>
              <small class="message-date typ--iii-1">{{ distanceInWords(message.date) }} ago</small>
            </li>
          </ul>
          <label :style="{ opacity: message.complete ? 0.5 : 1 }">
            <input v-model="message.complete" @input="todoComplete(message.id)"  type="checkbox" />
            <Message :value="message.content" />
          </label>
        </template>
      </li>
      <li v-if="state === SIGNIN">
        <h3>Welcome to Threades</h3>
        <p>
          Notes, todos, reminders and anything else you want save and catagorize
          all in one interface.
        </p>
        <p>Enter your email to sign up or sign in</p>
      </li>
      <li v-if="state === AWAIT_CONFIRM">
        <h3>Thank you</h3>
        <p>
          A confirmation email has been sent to {{email}}
        </p>
      </li>
    </ul>
    <div
      class="app-editor pos--bottom-left pos--bottom-right pad--all-md origin"
      :class="{showSidebar}"
    >
      <Editor
        :modelValue="content"
        @update:modelValue="handleUpdate"
        @submit="handleSubmit"
        :hashtags="hashtags"
        :selectedHashtags="selectedHashtags"
        class="editor"
      />
      <button
        @click="handleSubmit"
        class="app-submit pos--bottom-right ofst--right-lg rst--button"
        :disabled="sendDisabled"
      >
        {{ state === SIGNIN ? "Sign in" : "Post" }}
      </button>
    </div>
    <div class="app-sidebar rst--list vprt--top-left vprt--bottom-left origin" :class="{showSidebar}">
      <ul v-if="state === DEFAULT" class="app-sidebar-hashtags rst--list fll--parent-h ovr--scroll-y fll--parent-h">
        <li class="hashtags-tag">
          <button
            class="hashtags-tag-button rst--button pad--left-sm pad--right-sm typ--i"
            @click="addHashtagFilter(null)"
            
            :disabled="hashtagFilter.length === 0"
          >
            <span
              :style="{
                visibility: hashtagFilter.length === 0 ? 'visible' : 'hidden',
                color: '#ffffff'
              }"
              >&#10003;</span
            >&nbsp;
            <span :style="{ color: hashtagFilter.length === 0 ? '#cccccc' : '#ffffff' }">
              All
            </span>
          </button>
        </li>
        <li
          v-for="(tag, index) in hashtags"
          :key="index"
          class="hashtags-tag"
        >
          <button
            @click="addHashtagFilter(tag.id)"
            class="hashtags-tag-button rst--button pad--left-sm pad--right-sm typ--i"
            :style="{color: tag.color}"
          >
            <span
              :style="{
                visibility: hashtagFilter.includes(tag.id)
                  ? 'visible'
                  : 'hidden',
              }"
              >&#10003;</span
            >&nbsp;
            <span :style="{ color: hashtagFilter.includes(tag.id) ? desaturate(tag.color, 70) : tag.color }">
              {{ tag.name }}
            </span>
          </button>
        </li>
      </ul>
      <button v-show="state === DEFAULT" class="app-sidebar-signout rst--button pos--bottom-center pad--all-md ofst--bottom-sm" @click="handleSignOut">
        Sign out
      </button>
      <button v-if="state === DEFAULT && browserWidth < 600" class="app-sidebar-toggle pos--top-right rst--button typ--i+1" @click="sidebarToggle = !sidebarToggle"><span v-if="showSidebar">&#x21d0;</span> <span v-else>&#x21d2;</span></button>
    </div>
    
    
  </div>
</template>

<script>
import Editor from "@/components/Editor.vue";
import Message from "@/components/Message.vue";
import { formatDistance } from "date-fns";
import { isValidEmail, extractEmails } from "@/lib/util/validate_email";
import states from "@/lib/mixins/states";
import {debounce} from 'lodash'

export default {
  name: "App",
  components: { Editor, Message },
  mixins: [states(["DEFAULT", "SIGNIN", "AWAIT_CONFIRM"])],
  data: () => ({
    content: [],
    hashtagFilter: [],
    selectedHashtags: [],
    hasContent: false,
    email: "",
    lastHashTagUsed: null,
    resizeListener: null,
    sidebarToggle: false
  }),
  computed: {
    messages() {
      const messages = this.$store.getters["messages/parsedMessages"];
      return this.hashtagFilter.length
        ? messages.filter((message) => {
            return message.hashtags.some((tag) =>
              this.hashtagFilter.includes(tag)
            );
          })
        : messages;
    },
    browserWidth () {
      return this.$store.state.browserWidth
    },
    showSidebar () {
      if (this.browserWidth > 600 && this.state === this.DEFAULT) {
        return true
      }
      return this.sidebarToggle && this.state === this.DEFAULT;
    },
    hashtags () {
      return this.$store.getters['hashtags/parsedHashtags']
    },
    filteredHashtags() {
      return this.hashtags.filter(
        (tag) => !this.selectedHashtags.includes(tag.name)
      );
    },
    sendDisabled() {
      return this.state === this.SIGNIN
        ? !isValidEmail(this.email)
        : !this.hasContent;
    },
  },
  watch: {
    messages () {
      const {scrollHeight, clientHeight, scrollTop} = this.$refs?.messages;
      const overflow = scrollHeight - clientHeight;

      if (scrollTop + 20 >= overflow) {
        this.scrollToBottom();
      }
    }
  },
  async created () {
    let {success} =  await this.$store.dispatch("auth/check");
    if (success) {
      await this.$store.dispatch('hashtags/getAllHashtags');
      await this.$store.dispatch('messages/getAllMessages');
      this.state = this.DEFAULT;
      this.content = ["#"];
      
    } else {
      this.state = this.SIGNIN;
      this.content = [""];
    }
  },
  async mounted() {
    this.updateVh();
    window.addEventListener("resize", this.updateVh);
    this.scrollToBottom();
  },
  beforeUnmount () {
    window.removeEventListener("resize", this.updateVh);
  },
  methods: {
    updateVh: debounce(function () {
      this.$store.commit('setBrowserWidth', window.innerWidth);
      this.$store.dispatch('setVhVar', window.innerHeight);
      
    }),
    scrollToBottom () {
      this.$nextTick(() => {
        const {scrollHeight, clientHeight} = this.$refs?.messages;
        const overflow = scrollHeight - clientHeight;
        this.$refs.messages.scrollTop = overflow;
      })
    },
    desaturate: (color, amount) => updateColor(color, amount),
    todoComplete (id) {
      const message = this.$store.state.messages.all[id];
      message.complete = !message.complete;
      this.$store.dispatch('messages/updateMessage', {id, message})
    },
    addHashtagFilter(id) {
      this.hashtagFilter = id ? [id] : [];
      const tag = this.$store.state.hashtags.all[id]

      if (this.content.length) {
        const content = this.content.slice();
        const firstTextNodeSplit = content[0].split(" ");
        firstTextNodeSplit[0] = tag ? tag.name : this.lastHashTagUsed || ['#'];
        content[0] = firstTextNodeSplit.join(" ")
        this.content = content;
      }
      // const index = this.hashtagFilter.indexOf(tag)
      // if (index === -1) {
      //   this.hashtagFilter.push(tag);
      // } else {
      //   this.hashtagFilter.splice(index, 1);
      // }
    },
    checkForContent(content) {
      return (
        content
          .filter((str) => typeof str === "string" && str.trim())
          .reduce((accumulator, item) => {
            accumulator = [
              ...accumulator,
              ...item
                .split(" ")
                .map((str) => str.trim())
                .filter((str) => str && str.charAt(0) !== "#"),
            ];
            return accumulator;
          }, []).length > 0
      );
    },
    distanceInWords(date) {
      return formatDistance(date, Date.now());
    },
    getHashtags(content) {
      return content
        .filter((node) => typeof node === "string" && node.includes("#"))
        .reduce((accumulator, str) => {
          const tags = str.split(" ").filter((str) => str.charAt(0) === "#");
          accumulator = [...accumulator, ...tags];
          return accumulator;
        }, []);
    },
    handleUpdate(val) {
      if (this.state === this.DEFAULT) {
        this.selectedHashtags = this.getHashtags(val);
        this.hasContent = this.checkForContent(val);

        if (typeof val[0] === "string" && val[0].charAt(0) !== "#") {
          val[0] = "#" + val[0];
          return (this.content = val);
        } else if (typeof val[0] !== "string") {
          val.unshift("#");
          return (this.content = val);
        } else {
          return (this.content = val);
        }
      } else {
        this.email = extractEmails(val[0])[0];
        this.content = val;
      }
    },
    handleSubmit() {
      switch (this.state) {
        case this.SIGNIN:
          this.$store.dispatch("auth/signIn", { email: this.email });
          this.state = this.AWAIT_CONFIRM;
          this.content = ['']
          break;

        default:
          this.sendMessage();
          break;
      }
    },
    async sendMessage() {
      if (this.hasContent) {
        const contentHashtags = this.getHashtags(this.content);
        const currentHashtags = this.hashtags.map((tag) => tag.name);

        const hashtagsToAdd = contentHashtags.filter(
          (tag) => !currentHashtags.includes(tag)
        );

        if (hashtagsToAdd.length) {
          await this.$store.dispatch('hashtags/createHashtags', {hashtags: hashtagsToAdd});
        }

        const primaryHashtag = this.hashtags.find((tag) => tag.name === contentHashtags[0]) ;

        const hashtags = this.hashtags.filter((tag) => contentHashtags.includes(tag.name)).map(tag => tag.id);

        this.lastHashTagUsed = this.hashtags.find((tag) => tag.name === primaryHashtag) ;
        let message;

        if (primaryHashtag.name.includes("todo")) {
          message = {
            date: Date.now(),
            content: this.content,
            hashtags,
            primaryHashtag: primaryHashtag.id,
            type: "todo",
            complete: false,
          };
        } else if (primaryHashtag.name.includes("note")) {
          message = {
            date: Date.now(),
            content: this.content,
            hashtags,
            primaryHashtag: primaryHashtag.id,
            type: "note"
          };
        } else if (primaryHashtag.name.includes("reminder")) {
          message = {
            date: Date.now(),
            content: this.content,
            hashtags,
            primaryHashtag: primaryHashtag.id,
            type: "reminder"
          };
        } else {
          message = {
            date: Date.now(),
            content: this.content,
            hashtags,
            primaryHashtag,
            type: "default"
          };
        }

        this.$store.dispatch("messages/addMessage", message);
        this.hasContent = false;
        this.content = [primaryHashtag.name + " ", { tagName: "BR", children: null }];
      }
    },
    async handleSignOut () {
      try {
        await this.$store.dispatch('auth/signOut');
        this.state = this.SIGNIN
      } catch (err) {
        console.error(err)
      }
    }
  },
};
function updateColor(color, saturation){
    var col = hexToRgb(color);
    var sat = Number(saturation)/100;
    var gray = col.r * 0.3086 + col.g * 0.6094 + col.b * 0.0820;

    col.r = Math.round(col.r * sat + gray * (1-sat));
    col.g = Math.round(col.g * sat + gray * (1-sat));
    col.b = Math.round(col.b * sat + gray * (1-sat));

    return rgbToHex(col.r,col.g,col.b);
}

function componentToHex(c) {
    var hex = c.toString(16);
    return hex.length == 1 ? "0" + hex : hex;
}

function rgbToHex(r, g, b) {
    return "#" + componentToHex(r) + componentToHex(g) + componentToHex(b);
}

function hexToRgb(hex) {
    var result = /^#?([a-f\d]{2})([a-f\d]{2})([a-f\d]{2})$/i.exec(hex);
    return result ? {
        r: parseInt(result[1], 16),
        g: parseInt(result[2], 16),
        b: parseInt(result[3], 16)
    } : null;
}
</script>

<style lang="scss" scoped>
.app {
  height: 100vh; /* Fallback for browsers that do not support Custom Properties */
  height: calc(var(--vh, 1vh) * 100);

  &-editor {
    transition: transform .4s ease-in-out;

    .isWideScreen & {
      max-width: calc(100% - 12rem);
    }

    &.showSidebar {
      transform: translate(12rem, 0);
    }
  }

  &-messages {
    max-height: calc(100% - 5rem);
    transform: translate(0, -5rem);
    transition: transform .4s ease-in-out;

    .isWideScreen & {
      max-width: calc(100% - 12rem);
    }

    &.showSidebar {
      transform: translate(12rem, -5rem);
    }
    
    &-message + &-message {
      margin-top: 1.5rem;
    }
  }

  &-sidebar {
    border-right: solid thin scale-color($color: $background-dark, $lightness: 10%);
    background-color: scale-color($color: $background-dark, $lightness: 2%);
    width: 12rem;
    transform: translate(-100%, 0);
    transition: transform .4s ease-in-out;

    &.showSidebar {
      transform: translate(0, 0);
    }

    &-hashtags {
      max-height: calc(100% - 5rem);
    }

    &-signout {
      white-space: nowrap;
    }

    &-toggle {
      transform: translate(2.5rem, 0);
    }
  }

  &-submit {
    transform: translate(0, -1.5rem);
    
    &[disabled] {
      opacity: 0.1;
    }
  }
}

.hashtags-tag-button {
  min-width: 7rem;
  display: inline-block;
  height: 2.5rem;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  width: 100%;
}

.message-date {
  color: scale-color($background-dark, $lightness: 70%);
  text-transform: uppercase;
  letter-spacing: 0.1em;
}
</style>
