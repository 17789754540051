<script>
import { h, toRefs } from 'vue';
import { re_weburl } from '@/lib/regex';

export default {
  props: {
    value: Object,
  },
  setup(props) {
    const {value} = toRefs(props);
    return () => value.value.reduce((accumulator, node) => {
      switch(typeof node) {
        case "string":
          accumulator = node.split(/(#[a-z0-9_]+)/g).reduce((accumulator, node) => {
            if (node.charAt(0) === '#') {
              return accumulator
              // const hashtag = hashtags.value.find(hashtag => hashtag.name === node);
              // accumulator.push(h('b', {class: 'hashtag', style: {color: hashtag?.color ? hashtag.color : '#ffffff'}}, node));
            } else if (re_weburl.test(node)) { 
              const matches = node.match(re_weburl);
              
              for (const match of matches) {
                const nodeSplit = node.split(match);
                const start = nodeSplit.shift();
                accumulator.push(start);
                accumulator.push(h('a', {href: match, target: '_blank', class: 'link'}, match));
                console.log(match)
                node = nodeSplit.join(match)
              }

              accumulator.push(node)
            } else {
              accumulator.push(node)
            }
            return accumulator
          }, accumulator)
          break;
        
        case 'object':
          accumulator.push(h(node.tagName, {
            ...(node.attrs || {})
          }, node.children));
          break;
      }
      return accumulator
    }, [])
  },
}
</script>


<style lang="scss" scoped>

</style>