export default (states = [], _stateKey) => {
  _stateKey = _stateKey || states[0];
  states = states.reduce((accumulator, key) => {
    accumulator[key] = Symbol(key);
    return accumulator
  }, {});
  return {
    data: () => ({...states, _stateKey}),
    computed: {
      state: {
        get() {
          return this._stateKey in states ? states[this._stateKey] : null;
        },
        set(stateSymbol) {
          const stateKey = Object.keys(states).find(key => states[key] === stateSymbol);
          this._stateKey = stateKey in states ? stateKey : this._stateKey;
        }
      }
    }
  }
}